<template>
  <v-container fluid class="pa-0">
    <v-row dense>
        <v-col cols="12" v-for="(selector, n) in selectors" :key="selector.id">
          <selector-results
            :summary="round.teamsSummary"
            :selector="selector"
            :bracket="bracket"
            :division="division"
            v-if="n === 0"
          ></selector-results>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
// import * as mutations from '@/store/MutationTypes'
import SelectorResults from '@/components/Tournament/Brackets/SelectorResultsDisplay'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      loading: false,
      manualTeams: null
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'tournament',
      'getDivision'
    ]),
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.roundId)
    },
    bracket () {
      if (!this.round) return null
      return this.round.brackets[0]
    },
    selectors () {
      return this.round && this.round.teamSelectors
    }
  },
  methods: {
  },
  components: {
    SelectorResults
  }
}
</script>

<style>

</style>
